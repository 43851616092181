import React from "react";
import {
  format,
  eachDayOfInterval,
  isSameDay,
  isAfter,
  startOfDay,
} from "date-fns";
import { DailyQuestValidation } from "../dto/user";
import { Box, useMantineColorScheme } from "@mantine/core";
import PersistentTooltip from "./PersistentTooltip";

interface QuestContributionGraphProps {
  startDate: Date;
  endDate: Date | null;
  validations: DailyQuestValidation[];
  isMobile: boolean;
}

const QuestContributionGraph = ({
  startDate,
  endDate,
  validations,
  isMobile,
}: QuestContributionGraphProps) => {
  const { colorScheme } = useMantineColorScheme();
  const today = startOfDay(new Date());
  const actualEndDate = endDate ? startOfDay(new Date(endDate)) : today;
  const adjustedStartDate = startOfDay(new Date(startDate));

  // Si la date de début est dans le futur, ne rien afficher
  if (isAfter(adjustedStartDate, today)) {
    return null;
  }

  // N'afficher que les jours jusqu'à aujourd'hui ou la date de fin, selon ce qui vient en premier
  const displayEndDate = isAfter(actualEndDate, today) ? today : actualEndDate;

  const days = eachDayOfInterval({
    start: adjustedStartDate,
    end: displayEndDate,
  });

  return (
    <Box mt={4} style={{ display: "flex", gap: "3px", flexWrap: "wrap" }}>
      {days.map((day) => {
        // Ne pas afficher de carré pour les jours futurs
        if (isAfter(day, today)) {
          return null;
        }

        const isValidated = validations.some((validation) =>
          validation.date ? isSameDay(new Date(validation.date), day) : false
        );
        const tooltipLabel = `${format(day, "dd/MM/yyyy")}: ${
          isValidated ? "Validé" : "Non validé"
        }`;

        return (
          <PersistentTooltip
            key={day.toISOString()}
            label={tooltipLabel}
            isMobile={isMobile}
          >
            <Box
              style={{
                width: "12px",
                height: "12px",
                backgroundColor:
                  colorScheme === "light"
                    ? isValidated
                      ? "#9be9a8"
                      : "#ebedf0"
                    : isValidated
                    ? "#006d32"
                    : "#1e1e2e",
                borderRadius: "2px",
              }}
            />
          </PersistentTooltip>
        );
      })}
    </Box>
  );
};

export default QuestContributionGraph;
