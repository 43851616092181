import React, { useState } from "react";
import {
  Modal,
  Button,
  Text,
  SegmentedControl,
  Group,
  TextInput,
  Textarea,
} from "@mantine/core";
import { Quest } from "../dto/user";

interface StartQuestModalProps {
  quest: Quest;
  isOpen: boolean;
  onClose: () => void;
  onStart: (
    questId: number,
    startDate: string,
    customData: {
      customTitle?: string;
      customDescription?: string;
      customPrecision?: string;
    }
  ) => void;
}

const StartQuestModal: React.FC<StartQuestModalProps> = ({
  quest,
  isOpen,
  onClose,
  onStart,
}) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [startDate, setStartDate] = useState<"today" | "tomorrow">("today");
  const [customTitle, setCustomTitle] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const [customPrecision, setCustomPrecision] = useState("");

  const formatDateToString = (date: Date): string => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  const handleStart = () => {
    const selectedDate = startDate === "today" ? today : tomorrow;
    const dateString = formatDateToString(selectedDate);
    if (quest.id !== undefined) {
      onStart(quest.id, dateString, {
        customTitle: customTitle || undefined,
        customDescription: customDescription || undefined,
        customPrecision: customPrecision || undefined,
      });
      onClose();
    } else {
      console.error("Quest ID is undefined");
    }
  };

  const handleChange = (value: string) => {
    if (value === "today" || value === "tomorrow") {
      setStartDate(value);
    }
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Démarrer une quête">
      <Text mb="md">Quête: {quest.title}</Text>
      {quest.category?.value === "custom" && (
        <TextInput
          label="Titre personnalisé (optionnel)"
          value={customTitle}
          onChange={(e) => setCustomTitle(e.target.value)}
          mb="md"
        />
      )}
      {quest.category?.value === "custom" && (
        <Textarea
          label="Description personnalisée (optionnelle)"
          value={customDescription}
          onChange={(e) => setCustomDescription(e.target.value)}
          mb="md"
        />
      )}
      {quest.category?.value !== "custom" && (
        <Textarea
          label="Précision personnalisée (optionnelle)"
          value={customPrecision}
          onChange={(e) => setCustomPrecision(e.target.value)}
          mb="md"
        />
      )}
      <Text mb="md">Choisissez la date de début:</Text>
      <Group mb="md">
        <SegmentedControl
          value={startDate}
          onChange={handleChange}
          data={[
            { label: "Aujourd'hui", value: "today" },
            { label: "Demain", value: "tomorrow" },
          ]}
        />
      </Group>
      <Button onClick={handleStart} fullWidth>
        Démarrer la quête
      </Button>
    </Modal>
  );
};

export default StartQuestModal;
