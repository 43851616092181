import React, { useState, useEffect } from "react";
import { Box, Button, Group, Title } from "@mantine/core";
import { RadarChart } from "@mantine/charts";
import { areasOfLife } from "../utils/areasOfLife";
import { Skill, User } from "../dto/user";
import { TargetLevelModal } from "../Components/TargetLevelModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";

interface AreaLevelsSectionProps {
  skills: Skill[] | undefined;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AreaLevelsSection: React.FC<AreaLevelsSectionProps> = ({
  skills,
  user,
  setUser,
}) => {
  const [areaTargets, setAreaTargets] = useState<Record<string, number>>({});
  const [targetLevelModalOpened, setTargetLevelModalOpened] = useState(false);
  const api = useApi();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user?.areaOfLifeTargetLevel) {
      setAreaTargets(JSON.parse(user.areaOfLifeTargetLevel));
    }
  }, [user]);

  const updateTargetLevelsMutation = useMutation({
    mutationFn: (newTargets: Record<string, number>) =>
      api.put(`/user/${user?.id}/area-targets`, {
        areaOfLifeTargetLevel: JSON.stringify(newTargets),
      }),
    onSuccess: (_, newTargets) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setUser((prevUser) => ({
        ...prevUser,
        areaOfLifeTargetLevel: JSON.stringify(newTargets),
      }));
    },
  });

  const handleSaveTargets = (newTargets: Record<string, number>) => {
    setAreaTargets(newTargets);
    updateTargetLevelsMutation.mutate(newTargets);
  };

  const radarData = areasOfLife.map((area) => {
    const areaSkills =
      skills?.filter((skill) => skill.area?.value === area.value) || [];
    const averageLevel =
      areaSkills.length > 0
        ? areaSkills.reduce((sum, skill) => sum + (skill.userLevel || 0), 0) /
          areaSkills.length
        : 0;
    return {
      area: area.title,
      "Niveau actuel (en travaux)": Math.round(averageLevel * 10) / 10,
      Objectif: areaTargets[area.value] || 0,
    };
  });

  return (
    <Box mt={16}>
      <Group mt={16}>
        <Title order={3}>Niveaux par domaine de vie</Title>
        <Button onClick={() => setTargetLevelModalOpened(true)} variant="light">
          Définir des objectifs
        </Button>
      </Group>
      <RadarChart
        h={300}
        data={radarData}
        dataKey="area"
        withPolarRadiusAxis
        withLegend
        series={[
          {
            name: "Niveau actuel (en travaux)",
            color: "theme.4",
            opacity: 0.2,
          },
          { name: "Objectif", color: "orange.4", opacity: 0.2 },
        ]}
      />
      <TargetLevelModal
        opened={targetLevelModalOpened}
        onClose={() => setTargetLevelModalOpened(false)}
        initialTargets={areaTargets}
        onSave={handleSaveTargets}
      />
    </Box>
  );
};

export default AreaLevelsSection;
