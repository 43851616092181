import {
  Badge,
  Box,
  Group,
  Select,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { areasOfLife } from "../utils/areasOfLife";
import { useViewportSize } from "@mantine/hooks";
import { useUpdateUser } from "../hooks/useUpdateUser";
import SaveStatus from "../Components/SaveStatus";
import { useApi } from "../useApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  validateUserQuest,
  getUserQuests,
  invalidateUserQuest,
} from "../apiRoutes";
import { format } from "date-fns";
import QuestList from "../Components/QuestList";
import ContributionGraph from "../Components/ContributionGraph";
import { UserQuest } from "../dto/user";
import { useSkills } from "../context/SkillsContext";
import WelcomeMessage from "../Components/WelcomeMessage";
import AreaLevelsSection from "../Components/AreaLevelsSection";
import FinishQuestModalWrapper from "../Components/FinishQuestModalWrapper";
import ProfileButtons from "../Components/ProfileButtons";
import { useEvolutionData } from "../hooks/useEvolutionData";
import ChartEvolution from "../Components/ChartEvolution";
import PrioritySkillsSection from "../Components/PrioritySkillsSection";

function debounce<T extends (...args: any[]) => any>(func: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout> | null;

  const debouncedFunction = function (this: any, ...args: Parameters<T>) {
    const context = this;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };

  debouncedFunction.cancel = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debouncedFunction;
}

const Home: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const { skills } = useSkills();
  const evolutionData = useEvolutionData(skills);
  const { width } = useViewportSize();
  const [priorityArea, setPriorityArea] = useState("");
  const [dreams, setDreams] = useState("");
  const [goals, setGoals] = useState("");
  const [, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<
    "saved" | "saving" | "unsaved" | "error" | null
  >(null);
  const [userModifiedSomething, setUserModifiedSomething] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState<UserQuest | null>(null);
  const isInitializing = useRef(true);
  const lastSavedValues = useRef({
    priorityArea: "",
    dreams: "",
    goals: "",
  });
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: userQuests, isLoading: isLoadingQuests } = useQuery({
    queryKey: ["userQuests"],
    queryFn: () => getUserQuests(api),
  });

  const activeQuests = userQuests
    ? userQuests.filter((quest: UserQuest) => quest.endDate === null)
    : [];
  const totalQuestPoints =
    userQuests?.reduce((total, quest) => {
      return total + (quest.DailyQuestValidation?.length || 0);
    }, 0) || 0;

  const validateQuestMutation = useMutation({
    mutationFn: (data: { userQuestId: number; date: string }) =>
      validateUserQuest(api, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const cancelQuestMutation = useMutation({
    mutationFn: (userQuestId: number) =>
      api.delete(`/user-quest/${userQuestId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const handleCancelQuest = (userQuestId: number) => {
    cancelQuestMutation.mutate(userQuestId);
  };

  const handleValidateQuest = (userQuestId: number, date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    validateQuestMutation.mutate({ userQuestId, date: dateString });
  };

  const handleFinishQuest = (userQuestId: number) => {
    const quest = activeQuests?.find((q: UserQuest) => q.id === userQuestId);
    setSelectedQuest(quest || null);
  };

  const invalidateQuestMutation = useMutation({
    mutationFn: (data: { userQuestId: number; date: string }) =>
      invalidateUserQuest(api, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const handleInvalidateQuest = (userQuestId: number, date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    invalidateQuestMutation.mutate({ userQuestId, date: dateString });
  };

  const updateUser = useUpdateUser({
    onSuccess: (data) => {
      setUser((prevUser) => ({
        ...prevUser,
        priorityArea: data.priorityArea,
        dreams: data.dreams,
        goals: data.goals,
      }));
      setIsSaving(false);
      setSaveStatus("saved");
      lastSavedValues.current = {
        priorityArea: data.priorityArea,
        dreams: data.dreams,
        goals: data.goals,
      };
    },
    onError: () => {
      setIsSaving(false);
      setSaveStatus("error");
    },
  });

  const debouncedSave = useRef(
    debounce(
      (updatedUser: {
        id: number;
        priorityArea: string;
        dreams: string;
        goals: string;
      }) => {
        if (saveStatus === "saving") {
          debouncedSave.cancel();
        }
        setIsSaving(true);
        setSaveStatus("saving");
        updateUser.mutate(updatedUser);
      },
      2000
    )
  ).current;

  const handleSaveStatusChange = (
    status: "saved" | "saving" | "unsaved" | "error" | null
  ) => {
    setSaveStatus(status);
  };

  useEffect(() => {
    if (user) {
      if (isInitializing.current) {
        setPriorityArea(user.priorityArea || "");
        setDreams(user.dreams || "");
        setGoals(user.goals || "");
        lastSavedValues.current = {
          priorityArea: user.priorityArea || "",
          dreams: user.dreams || "",
          goals: user.goals || "",
        };
        isInitializing.current = false;
        setSaveStatus(null);
      } else if (
        user.id !== undefined &&
        (priorityArea !== lastSavedValues.current.priorityArea ||
          dreams !== lastSavedValues.current.dreams ||
          goals !== lastSavedValues.current.goals) &&
        (priorityArea !== "" || dreams !== "" || goals !== "")
      ) {
        setSaveStatus("unsaved");
        setUserModifiedSomething(true);
        debouncedSave({
          id: user.id,
          priorityArea,
          dreams,
          goals,
        });
      } else if (userModifiedSomething) {
        setSaveStatus("saved");
      }
    }

    return () => {
      debouncedSave.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityArea, dreams, goals, user]);

  return (
    <Box p={16}>
      <Title>Bienvenue {user?.pseudo}</Title>

      <WelcomeMessage />

      <Group align="center" mt={16}>
        <Title order={3}>Petits pas</Title>
        <Badge variant="filled" color="theme">
          {totalQuestPoints} points
        </Badge>
        <ProfileButtons
          username={user?.pseudo ?? ""}
          isPublicProfile={user?.isPublicProfile ?? false}
        />
      </Group>
      <Text c="dimmed" size="xs">
        Choisis jusqu'à 3 actions quotidiennes pour te rapprocher des tes
        objectifs ou développer tes compétences
      </Text>

      {isLoadingQuests ? (
        <Text>Chargement des quêtes en cours...</Text>
      ) : userQuests && userQuests.length > 0 ? (
        <>
          <ContributionGraph userQuests={userQuests} />
          <QuestList
            userQuests={userQuests ?? []}
            onValidateQuest={handleValidateQuest}
            onInvalidateQuest={handleInvalidateQuest}
            onFinishQuest={handleFinishQuest}
            onCancelQuest={handleCancelQuest}
          />
        </>
      ) : (
        <Text mt={16}>Aucune quête en cours pour le moment.</Text>
      )}

      <Group grow={width > 1200} align="flex-start" w="100%">
        <AreaLevelsSection skills={skills} user={user} setUser={setUser} />

        <Box>
          <Select
            label="Domaine de vie prioritaire"
            placeholder="Choisissez un domaine"
            data={areasOfLife.map((area) => ({
              value: area.value,
              label: area.title,
            }))}
            value={priorityArea}
            onChange={(value) => setPriorityArea(value || "")}
            style={{ width: 200 }}
            mt={16}
          />

          <PrioritySkillsSection onSaveStatusChange={handleSaveStatusChange} />
        </Box>
      </Group>

      <Group grow={width > 1200} align="flex-start" w="100%">
        <Textarea
          label="Vos rêves long terme"
          placeholder="Racontez-nous vos rêves à long terme"
          minRows={5}
          autosize
          value={dreams}
          onChange={(event) => setDreams(event.currentTarget.value)}
          mt={16}
          w="100%"
        />
        <Textarea
          label="Vos objectifs pour cette année"
          placeholder="Racontez-nous vos objectifs pour cette année"
          minRows={5}
          autosize
          value={goals}
          onChange={(event) => setGoals(event.currentTarget.value)}
          mt={16}
          w="100%"
        />
      </Group>

      {evolutionData.length ? (
        <>
          <Title order={3} mt="md" mb="sm">
            Progression des compétences
          </Title>
          <ChartEvolution chartData={evolutionData} dataKey="total" />
        </>
      ) : null}

      {selectedQuest && (
        <FinishQuestModalWrapper
          selectedQuest={selectedQuest}
          onClose={() => setSelectedQuest(null)}
        />
      )}

      <SaveStatus saveStatus={saveStatus} />
    </Box>
  );
};

export default Home;
