import React from "react";
import { Card, Text, Badge, Button, Group } from "@mantine/core";
import { Quest } from "../dto/user";

interface QuestCardProps {
  quest: Quest;
  onStart: (questId: number) => void;
}

const categoryColors: { [key: string]: string } = {
  habit: "yellow",
  custom: "green",
  book: "orange",
};

const QuestCard: React.FC<QuestCardProps> = ({ quest, onStart }) => {
  const getCategoryColor = (categoryValue: string | undefined) => {
    if (!categoryValue) return "gray";
    return categoryColors[categoryValue] || "pink";
  };

  const categoryColor = getCategoryColor(quest.category?.value);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder w={280}>
      <Group justify="space-between" mb="xs">
        <Text fw={500}>{quest.title}</Text>
        <Badge color={categoryColor} variant="light">
          {quest.category?.name}
        </Badge>
      </Group>

      <Text size="sm" c="dimmed">
        {quest.description}
      </Text>

      <Button
        variant="light"
        fullWidth
        mt="md"
        radius="md"
        onClick={() => onStart(quest.id ?? 0)}
      >
        Commencer la quête
      </Button>
    </Card>
  );
};

export default QuestCard;
