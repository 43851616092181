import React from "react";
import { useForm } from "@mantine/form";
import { TextInput, Select, Button, Box } from "@mantine/core";
import RichTextEditorField from "../Components/RichTextEditorField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { notifications } from "@mantine/notifications";
import { FeedbackCategory } from "../dto/user";

interface FeedbackFormProps {
  pageUrl?: string;
  onSubmitSuccess?: () => void;
}

interface FeedbackFormValues {
  title: string;
  content: string;
  category: FeedbackCategory;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  pageUrl,
  onSubmitSuccess,
}) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const form = useForm<FeedbackFormValues>({
    initialValues: {
      title: "",
      content: "",
      category: "" as FeedbackCategory,
    },
    validate: {
      title: (value) => (value.length > 0 ? null : "Le titre est requis"),
      content: (value) => (value.length > 0 ? null : "Le contenu est requis"),
      category: (value) => (value ? null : "La catégorie est requise"),
    },
  });

  const mutation = useMutation<void, Error, FeedbackFormValues>({
    mutationFn: (values) => {
      const titleWithUrl = pageUrl
        ? `${values.title} (Page: ${pageUrl})`
        : values.title;
      return api.post("/feedback", { ...values, title: titleWithUrl });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userFeedback"] });
      notifications.show({
        title: "Feedback soumis",
        message: "Merci pour votre feedback !",
        color: "green",
      });
      form.reset();
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    onError: () => {
      notifications.show({
        title: "Erreur",
        message: "Échec de la soumission du feedback. Veuillez réessayer.",
        color: "red",
      });
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    mutation.mutate(values);
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Titre"
          placeholder="Entrez un titre pour votre feedback"
          {...form.getInputProps("title")}
        />
        <Select
          label="Catégorie"
          placeholder="Sélectionnez une catégorie"
          data={[
            { value: "BUG", label: "Rapport de bug" },
            { value: "FEATURE_REQUEST", label: "Demande de fonctionnalité" },
            { value: "HELP", label: "Aide" },
            { value: "GENERAL_FEEDBACK", label: "Feedback général" },
            { value: "OTHER", label: "Autre" },
          ]}
          {...form.getInputProps("category")}
        />
        <RichTextEditorField
          content={form.values.content}
          onChange={(content) => form.setFieldValue("content", content)}
        />
        <Button type="submit" mt="md" loading={mutation.isPending}>
          Soumettre le feedback
        </Button>
      </form>
    </Box>
  );
};

export default FeedbackForm;
