import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Text,
  Button,
  Stack,
  Paper,
  Textarea,
  LoadingOverlay,
  ScrollArea,
  Loader,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import ReactMarkdown from "react-markdown";
import { ChatCompletionMessageParam } from "openai/resources";
import { io, Socket } from "socket.io-client";
import { baseUrl } from "../../useApi";
import { debounce } from "lodash";

interface ChatGameProps {
  gameData: {
    id: number;
    description: string;
    timeLimit: number;
  };
  gameState: "not_started" | "started" | "finished" | "submitted";
  setGameState: React.Dispatch<
    React.SetStateAction<"not_started" | "started" | "finished" | "submitted">
  >;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
}

const ChatGame: React.FC<ChatGameProps> = ({
  gameData,
  gameState,
  setGameState,
  timeLeft,
  setTimeLeft,
}) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [score, setScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState("");
  const [chatHistory, setChatHistory] = useState<ChatCompletionMessageParam[]>(
    []
  );
  const [currentMessage, setCurrentMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [isInitialMessageComplete, setIsInitialMessageComplete] =
    useState(false);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const debouncedSetIsTyping = useMemo(
    () => debounce(() => setIsTyping(false), 1000),
    []
  );

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const setupSocket = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("user") || "{}").accessToken;
    const newSocket: Socket = io(baseUrl, {
      withCredentials: true,
      auth: { token },
    });

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    newSocket.on("chatResponse", (chunk: string) => {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage && lastMessage.role === "assistant") {
          return [
            ...prev.slice(0, -1),
            { ...lastMessage, content: lastMessage.content + chunk },
          ];
        } else {
          return [...prev, { role: "assistant", content: chunk }];
        }
      });
      setIsTyping(true);
      debouncedSetIsTyping();
    });

    newSocket.on("chatComplete", () => {
      setIsTyping(false);
      setIsInitialMessageComplete(true);
      setIsSubmitting(false);
    });

    newSocket.on("chatError", (error) => {
      console.error("Error in chat:", error);
      notifications.show({
        title: "Erreur",
        message: "Une erreur est survenue lors de la conversation.",
        color: "red",
      });
      setIsTyping(false);
      setIsSubmitting(false);
    });

    newSocket.on(
      "chatGameResultSubmitted",
      (result: { score: number; feedback: string }) => {
        setScore(result.score);
        setFeedback(result.feedback);
        setIsSubmitting(false);
        setGameState("submitted");
        notifications.show({
          title: "Résultat enregistré",
          message: "Votre partie a été sauvegardée avec succès.",
          color: "green",
        });
      }
    );

    newSocket.on("chatGameResultError", (error: { message: string }) => {
      setIsSubmitting(false);
      setGameState("finished");
      notifications.show({
        title: "Erreur",
        message: error.message,
        color: "red",
      });
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [setGameState, debouncedSetIsTyping]);

  useEffect(() => {
    const cleanup = setupSocket();
    return () => {
      cleanup();
      debouncedSetIsTyping.cancel();
    };
  }, [setupSocket, debouncedSetIsTyping]);

  useEffect(() => {
    if (gameState === "started" && startTime === null) {
      setStartTime(Date.now());
    }
  }, [gameState, startTime]);

  useEffect(() => {
    if (gameState === "started" && timeLeft === 0) {
      setGameState("finished");
    }
  }, [timeLeft, gameState, setGameState]);

  useEffect(() => {
    if (gameState === "finished" && !isSubmitting) {
      submitGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, isSubmitting]);

  const submitGame = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    if (socket) {
      const timeSpent = startTime
        ? Math.min(
            Math.floor((Date.now() - startTime) / 1000),
            gameData.timeLimit
          )
        : gameData.timeLimit;
      socket.emit("submitChatGameResult", {
        miniGameId: gameData.id,
        timeSpent: timeSpent,
        chatHistory: chatHistory,
        isTimeUp: timeLeft === 0,
      });
    }
  }, [
    isSubmitting,
    socket,
    startTime,
    gameData.id,
    gameData.timeLimit,
    chatHistory,
    timeLeft,
  ]);

  const handleStartGame = () => {
    setGameState("started");
    setTimeLeft(gameData.timeLimit);
    setIsTyping(true);
    setIsInitialMessageComplete(false);
    setIsSubmitting(true);
    if (socket) {
      socket.emit("startChatGame", { miniGameId: gameData.id });
    }
  };

  const handleSendMessage = () => {
    if (
      currentMessage.trim() &&
      socket &&
      !isTyping &&
      isInitialMessageComplete &&
      gameState === "started"
    ) {
      setIsTyping(true);
      const newMessage: ChatCompletionMessageParam = {
        role: "user",
        content: currentMessage.trim(),
      };
      setChatHistory((prev) => [...prev, newMessage]);
      socket.emit("sendChatMessage", {
        miniGameId: gameData.id,
        message: currentMessage.trim(),
        chatHistory: [...chatHistory, newMessage],
      });
      setCurrentMessage("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const getUserMessageStyle = () => ({
    alignSelf: "flex-end" as const,
    maxWidth: "80%",
    backgroundColor:
      colorScheme === "dark" ? theme.colors.blue[9] : theme.colors.blue[1],
    color: colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.gray[9],
  });

  const getAssistantMessageStyle = () => ({
    alignSelf: "flex-start" as const,
    maxWidth: "80%",
    backgroundColor:
      colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
    color: colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.gray[9],
  });

  if (gameState === "not_started") {
    return (
      <Button onClick={handleStartGame} mt="md">
        Commencer le jeu
      </Button>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={isSubmitting} />
      <ScrollArea h={400} mb="md" viewportRef={scrollAreaRef}>
        <Stack ref={chatAreaRef}>
          {chatHistory.map((message, index) => (
            <Paper
              key={index}
              p="xs"
              withBorder
              style={
                message.role === "user"
                  ? getUserMessageStyle()
                  : getAssistantMessageStyle()
              }
            >
              <ReactMarkdown>
                {typeof message.content === "string" ? message.content : ""}
              </ReactMarkdown>
            </Paper>
          ))}
          {isTyping && (
            <Paper p="xs" withBorder style={getAssistantMessageStyle()}>
              <Loader type="dots" size="sm" />
            </Paper>
          )}
        </Stack>
      </ScrollArea>
      {gameState === "started" && !isSubmitting && (
        <>
          <Textarea
            placeholder="Entrez votre message ici"
            value={currentMessage}
            onChange={(event) => setCurrentMessage(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            mb="md"
          />
          <Button
            onClick={handleSendMessage}
            disabled={
              isTyping || !currentMessage.trim() || !isInitialMessageComplete
            }
          >
            Envoyer
          </Button>
          {isInitialMessageComplete && (
            <Button onClick={() => setGameState("finished")} ml="md">
              Terminer le jeu
            </Button>
          )}
        </>
      )}
      {(gameState === "finished" || gameState === "submitted") && (
        <Stack>
          <Text fw={700} size="lg" mb="xs">
            Jeu terminé !
          </Text>
          {score !== null && <Text>Note : {score.toFixed(1)}/10</Text>}
          {feedback && (
            <Paper p="md" withBorder>
              <Text fw={700} mb="xs">
                Feedback :
              </Text>
              <ReactMarkdown>{feedback}</ReactMarkdown>
            </Paper>
          )}
        </Stack>
      )}
    </div>
  );
};

export default ChatGame;
