import {
  UnstyledButton,
  Badge,
  Text,
  Group,
  rem,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconBulb,
  IconUser,
  // IconCheckbox,
  IconSettings,
  IconUserShield,
  IconMessageCircle2,
  IconTrophy,
  // IconDeviceGamepad,
} from "@tabler/icons-react";
import classes from "./NavBar.module.css";
import { areasOfLifeExtended } from "../utils/areasOfLife";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export function NavBar({ close }: { close: () => void }) {
  const { user } = useContext(UserContext);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();

  const links = [
    { icon: IconBulb, label: "Accueil", notifications: 0, link: "/" },
    // { icon: IconBulb, label: "Actions", notifications: 3, link: "/" },
    // { icon: IconCheckbox, label: "Tâches", notifications: 4, link: "/" },
    { icon: IconTrophy, label: "Achèvements", link: "/achievements" },
    { icon: IconUser, label: "Annuaire", link: "/users" },
    { icon: IconSettings, label: "Paramètres", link: "/settings" },
    { icon: IconMessageCircle2, label: "Feedback", link: "/feedback" },
    // { icon: IconDeviceGamepad, label: "Jeux", link: "/mini-game" },
  ];

  if (user?.role === "admin") {
    links.push({
      icon: IconUserShield,
      label: "Admin Skills",
      link: "/admin-skills",
    });
    links.push({
      icon: IconUserShield,
      label: "Admin Quests",
      link: "/admin-quests",
    });
    links.push({
      icon: IconUserShield,
      label: "Admin Feedbacks",
      link: "/admin-feedbacks",
    });
    links.push({
      icon: IconUserShield,
      label: "Admin MiniGames",
      link: "/admin-mini-games",
    });
    links.push({
      icon: IconUserShield,
      label: "MiniGame #1",
      link: "/mini-game/1",
    });
    links.push({
      icon: IconUserShield,
      label: "MiniGame #4",
      link: "/mini-game/4",
    });
  }

  const mainLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      className={classes.mainLink}
      onClick={() => {
        navigate(link.link);
        close();
      }}
    >
      <div className={classes.mainLinkInner}>
        <link.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
        <span>{link.label}</span>
      </div>
      {link.notifications !== 0 && link.notifications && (
        <Badge size="sm" variant="filled" circle>
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ));

  const collectionLinks = areasOfLifeExtended.map((collection) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        navigate(`/areas/${collection.value}`);
        close();
      }}
      key={collection.title}
      className={classes.collectionLink}
    >
      <span style={{ marginRight: rem(9), fontSize: rem(16) }}>
        {collection.emoji}
      </span>{" "}
      {collection.title}
    </a>
  ));

  return (
    <nav className={classes.navbar}>
      <div
        className={classes.section}
        style={{
          borderBottom: `1px solid ${
            colorScheme === "light"
              ? theme.colors.gray[3]
              : theme.colors.dark[4]
          }`,
        }}
      >
        <div className={classes.mainLinks}>{mainLinks}</div>
      </div>

      <div className={classes.section}>
        <Group className={classes.collectionsHeader} justify="space-between">
          <Text size="xs" fw={500} c="dimmed">
            Domaines
          </Text>
        </Group>
        <div className={classes.collections}>{collectionLinks}</div>
      </div>
    </nav>
  );
}
