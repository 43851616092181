import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import {
  Box,
  Title,
  Text,
  Table,
  Select,
  Modal,
  Button,
  ScrollArea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { FeedbackStatus } from "../dto/user";
import { translateCategory } from "./FeedbackList";

interface Feedback {
  id: number;
  title: string;
  content: string;
  category: string;
  status: FeedbackStatus;
  createdAt: string;
  user: {
    pseudo: string;
  };
}

const AdminFeedback = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(
    null
  );

  const {
    data: allFeedback,
    isLoading,
    error,
  } = useQuery<Feedback[]>({
    queryKey: ["allFeedback"],
    queryFn: () => api.get("/feedback/all").then((res) => res.data),
  });

  const updateStatusMutation = useMutation<
    void,
    Error,
    { id: number; status: FeedbackStatus }
  >({
    mutationFn: ({ id, status }) => api.put(`/feedback/${id}`, { status }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allFeedback"] });
      notifications.show({
        title: "Statut mis à jour",
        message: "Le statut du feedback a été mis à jour avec succès.",
        color: "green",
      });
    },
    onError: () => {
      notifications.show({
        title: "Erreur",
        message: "Échec de la mise à jour du statut. Veuillez réessayer.",
        color: "red",
      });
    },
  });

  if (isLoading) return <Text>Chargement...</Text>;
  if (error)
    return <Text c="red">Erreur lors du chargement des feedbacks</Text>;

  const handleStatusChange = (id: number, newStatus: FeedbackStatus) => {
    updateStatusMutation.mutate({ id, status: newStatus });
  };

  const handleRowClick = (feedback: Feedback) => {
    setSelectedFeedback(feedback);
  };

  return (
    <Box>
      <Title order={2} mb="md">
        Tous les Feedbacks Utilisateurs
      </Title>
      <ScrollArea>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Titre</Table.Th>
              <Table.Th>Catégorie</Table.Th>
              <Table.Th>Utilisateur</Table.Th>
              <Table.Th>Statut</Table.Th>
              <Table.Th>Soumis le</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {allFeedback?.map((feedback) => (
              <Table.Tr
                key={feedback.id}
                onClick={() => handleRowClick(feedback)}
                style={{ cursor: "pointer" }}
              >
                <Table.Td>{feedback.title}</Table.Td>
                <Table.Td>
                  {translateCategory(feedback.category as any)}
                </Table.Td>
                <Table.Td>{feedback.user.pseudo}</Table.Td>
                <Table.Td onClick={(e) => e.stopPropagation()}>
                  <Select
                    value={feedback.status}
                    onChange={(newStatus) => {
                      if (newStatus) {
                        handleStatusChange(
                          feedback.id,
                          newStatus as FeedbackStatus
                        );
                      }
                    }}
                    data={[
                      { value: "OPEN", label: "Ouvert" },
                      { value: "IN_PROGRESS", label: "En cours" },
                      { value: "CLOSED", label: "Fermé" },
                      { value: "RESOLVED", label: "Résolu" },
                    ]}
                    miw={100}
                  />
                </Table.Td>
                <Table.Td>
                  {new Date(feedback.createdAt).toLocaleDateString()}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>

      <Modal
        opened={!!selectedFeedback}
        onClose={() => setSelectedFeedback(null)}
        title="Détails du Feedback"
        size="lg"
      >
        {selectedFeedback && (
          <>
            <Title order={3}>{selectedFeedback.title}</Title>
            <Text mt="md" mb="xs">
              <strong>Catégorie:</strong>{" "}
              {translateCategory(selectedFeedback.category as any)}
            </Text>
            <Text mb="xs">
              <strong>Utilisateur:</strong> {selectedFeedback.user.pseudo}
            </Text>
            <Text mb="xs">
              <strong>Statut:</strong> {selectedFeedback.status}
            </Text>
            <Text mb="xs">
              <strong>Soumis le:</strong>{" "}
              {new Date(selectedFeedback.createdAt).toLocaleDateString()}
            </Text>
            <Text mt="md" mb="xs">
              <strong>Contenu:</strong>
            </Text>
            <Text
              dangerouslySetInnerHTML={{ __html: selectedFeedback.content }}
            />
            <Button mt="xl" onClick={() => setSelectedFeedback(null)}>
              Fermer
            </Button>
          </>
        )}
      </Modal>
    </Box>
  );
};

export default AdminFeedback;
