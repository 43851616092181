import {
  Box,
  Title,
  Table,
  Group,
  Button,
  Modal,
  TextInput,
  Textarea,
  Select,
  MultiSelect,
  ComboboxItem,
} from "@mantine/core";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Quest, QuestCategory, Skill } from "../dto/user";
import { useApi } from "../useApi";
import {
  getQuests,
  createEditQuest,
  getSkills,
  getQuestCategories,
} from "../apiRoutes";
import { useForm } from "@mantine/form";

const AdminQuests = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [modalOpened, setModalOpened] = useState(false);
  const [editingQuest, setEditingQuest] = useState<Quest | null>(null);

  const {
    data: quests,
    isLoading: questsLoading,
    error: questsError,
  } = useQuery<Quest[], Error>({
    queryKey: ["quests"],
    queryFn: () => getQuests(api),
  });

  const {
    data: skills,
    isLoading: skillsLoading,
    error: skillsError,
  } = useQuery<Skill[], Error>({
    queryKey: ["skills"],
    queryFn: () => getSkills(api),
  });

  const {
    data: questCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery<QuestCategory[], Error>({
    queryKey: ["questCategories"],
    queryFn: () => getQuestCategories(api),
  });

  const createEditQuestMutation = useMutation({
    mutationFn: (quest: Omit<Quest, "id"> | Quest) =>
      createEditQuest(api, quest),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["quests"] });
      setModalOpened(false);
      form.reset();
      setEditingQuest(null);
    },
  });

  const form = useForm({
    initialValues: {
      id: 0,
      title: "",
      description: "",
      categoryId: "",
      skillIds: [] as string[],
    },
    validate: {
      title: (value) => (value.length > 0 ? null : "Title is required"),
      description: (value) =>
        value.length > 0 ? null : "Description is required",
      categoryId: (value) => (value ? null : "Category is required"),
    },
  });

  const handleOpenModal = (quest?: Quest) => {
    if (quest) {
      setEditingQuest(quest);
      form.setValues({
        id: quest.id,
        title: quest.title,
        description: quest.description,
        categoryId: quest.category?.id?.toString() || "",
        skillIds:
          quest.skills?.map(
            (questSkill) => questSkill.skillId?.toString() || ""
          ) || [],
      });
    } else {
      setEditingQuest(null);
      form.reset();
    }
    setModalOpened(true);
  };

  const handleSubmit = (values: typeof form.values) => {
    const questData: Partial<Quest> = {
      title: values.title,
      description: values.description,
      categoryId: parseInt(values.categoryId, 10),
      skills: values.skillIds.map((id) => ({ skillId: parseInt(id, 10) })),
    };
    if (editingQuest) {
      questData.id = editingQuest.id;
    }
    createEditQuestMutation.mutate(questData as Quest);
  };

  const prepareCategoryData = (): ComboboxItem[] => {
    if (!questCategories) return [];
    return questCategories
      .filter(
        (category) => category.id !== undefined && category.name !== undefined
      )
      .map((category) => ({
        value: category.id!.toString(),
        label: category.name!,
      }));
  };

  if (questsLoading || skillsLoading || categoriesLoading)
    return <div>Loading...</div>;
  if (questsError || skillsError || categoriesError)
    return <div>Error: {(questsError || skillsError)?.message}</div>;

  return (
    <Box p={16}>
      <Group mb={16}>
        <Title>Admin Quests</Title>
        <Button onClick={() => handleOpenModal()}>Create New Quest</Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Category</Table.Th>
            <Table.Th>Related Skills</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {quests &&
            quests.map((quest: Quest) => (
              <Table.Tr
                key={quest.id}
                onClick={() => handleOpenModal(quest)}
                style={{ cursor: "pointer" }}
              >
                <Table.Td>{quest.id}</Table.Td>
                <Table.Td>{quest.title}</Table.Td>
                <Table.Td>
                  {quest.category ? quest.category.name : null}
                </Table.Td>
                <Table.Td>{quest.skills ? quest.skills.length : 0}</Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingQuest(null);
          form.reset();
        }}
        title={editingQuest ? "Edit Quest" : "Create New Quest"}
        size="lg"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Title"
            placeholder="Enter quest title"
            {...form.getInputProps("title")}
            required
            w="100%"
          />
          <Textarea
            label="Description"
            placeholder="Enter quest description"
            mt="md"
            {...form.getInputProps("description")}
            required
          />
          <Select
            label="Category"
            placeholder="Select a category"
            data={prepareCategoryData()}
            mt="md"
            {...form.getInputProps("categoryId")}
            required
            searchable
          />
          <MultiSelect
            label="Related Skills"
            placeholder="Select related skills"
            data={
              skills
                ?.map((skill) => ({
                  value: skill.id?.toString() || "",
                  label: `${skill.name} - ${skill.area?.value}`,
                }))
                .filter((item) => item.value !== "") || []
            }
            mt="md"
            {...form.getInputProps("skillIds")}
            searchable
          />
          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={createEditQuestMutation.isPending}>
              {editingQuest ? "Update Quest" : "Create Quest"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default AdminQuests;
