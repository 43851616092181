import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Box,
  Text,
  Title,
  Button,
  Modal,
  ScrollArea,
  TypographyStylesProvider,
  Paper,
  Group,
} from "@mantine/core";
import { format } from "date-fns";
import { useApi } from "../useApi";
import { MiniGameResult } from "../dto/user";
import { IconCheck, IconX } from "@tabler/icons-react";

interface MiniGameResultsAccordionProps {
  skillId: number;
}

interface ChatMessage {
  role: string;
  content: string;
}

interface QuizAnswer {
  questionId: number;
  userAnswer: string;
  correctAnswer: string;
  isCorrect: boolean;
}

const MiniGameResultsAccordion: React.FC<MiniGameResultsAccordionProps> = ({
  skillId,
}) => {
  const api = useApi();
  const [selectedChat, setSelectedChat] = useState<ChatMessage[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuizAnswers, setSelectedQuizAnswers] = useState<
    QuizAnswer[] | null
  >(null);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);

  const {
    data: miniGameResults,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["miniGameResults", skillId],
    queryFn: () =>
      api.get(`/mini-games/results/skill/${skillId}`).then((res) => res.data),
    enabled: !!skillId,
  });

  const openChatModal = (chatHistory: any | undefined) => {
    if (!chatHistory) {
      console.error("Chat history is undefined");
      return;
    }
    try {
      const parsedChat = JSON.parse(chatHistory) as ChatMessage[];
      setSelectedChat(parsedChat);
      setIsModalOpen(true);
    } catch (e) {
      console.error("Failed to parse chat history:", e);
    }
  };

  const openQuizModal = (quizAnswers: any | undefined) => {
    if (!quizAnswers) {
      console.error("Quiz answers are undefined");
      return;
    }
    try {
      const parsedAnswers = JSON.parse(quizAnswers) as QuizAnswer[];
      setSelectedQuizAnswers(parsedAnswers);
      setIsQuizModalOpen(true);
    } catch (e) {
      console.error("Failed to parse quiz answers:", e);
    }
  };

  if (isLoading) {
    return <Text>Chargement des résultats des mini-jeux...</Text>;
  }

  if (error) {
    return (
      <Text c="red">
        Erreur lors du chargement des résultats des mini-jeux:{" "}
        {(error as Error).message}
      </Text>
    );
  }

  if (!miniGameResults || miniGameResults.length === 0) {
    return (
      <Text>Aucun résultat de mini-jeu disponible pour cette compétence.</Text>
    );
  }

  return (
    <Box>
      <Title order={3} mb={10}>
        Résultats des Mini-Jeux
      </Title>
      <Accordion>
        {miniGameResults.map((result: MiniGameResult) => (
          <Accordion.Item key={result.id} value={`game-${result.id}`}>
            <Accordion.Control>
              {result.miniGame?.title || "Titre inconnu"} - Score:{" "}
              {result.score?.toFixed(2) || "N/A"}
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                Date:{" "}
                {result.createdAt
                  ? format(new Date(result.createdAt), "dd/MM/yyyy HH:mm")
                  : "Date inconnue"}
              </Text>
              <Text>Temps passé: {result.timeSpent ?? "N/A"} secondes</Text>
              <Text>Feedback: {result.feedback || "Aucun feedback"}</Text>
              <Group mt={10}>
                {result.chatHistory && (
                  <Button onClick={() => openChatModal(result.chatHistory)}>
                    Voir l'historique du chat
                  </Button>
                )}
                {result.quizAnswers && (
                  <Button onClick={() => openQuizModal(result.quizAnswers)}>
                    Voir les réponses du quiz
                  </Button>
                )}
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Historique du chat"
        size="lg"
      >
        <ScrollArea h={400}>
          {selectedChat &&
            selectedChat.map((message, index) => (
              <Box
                key={index}
                mb={10}
                p={10}
                style={{
                  backgroundColor:
                    message.role === "user"
                      ? "#f0f0f0"
                      : message.role === "system"
                      ? "#ffe6e6"
                      : "#e6f3ff",
                  borderRadius: "5px",
                }}
              >
                <Text fw={700}>
                  {message.role === "user"
                    ? "Vous"
                    : message.role === "system"
                    ? "Système"
                    : "Assistant"}
                </Text>
                <TypographyStylesProvider>
                  <div dangerouslySetInnerHTML={{ __html: message.content }} />
                </TypographyStylesProvider>
              </Box>
            ))}
        </ScrollArea>
      </Modal>

      <Modal
        opened={isQuizModalOpen}
        onClose={() => setIsQuizModalOpen(false)}
        title="Résultats du Quiz"
        size="lg"
      >
        <ScrollArea h={400}>
          {selectedQuizAnswers &&
            selectedQuizAnswers.map((answer, index) => (
              <Paper key={index} p="md" withBorder mb="md">
                <Text fw={600}>Question {index + 1}:</Text>
                <Text mt="xs">Votre réponse: {answer.userAnswer}</Text>
                <Text mt="xs">Réponse correcte: {answer.correctAnswer}</Text>
                {answer.isCorrect ? (
                  <Group mt="xs">
                    <IconCheck color="green" />
                    <Text c="green">Correct</Text>
                  </Group>
                ) : (
                  <Group mt="xs">
                    <IconX color="red" />
                    <Text c="red">Incorrect</Text>
                  </Group>
                )}
              </Paper>
            ))}
        </ScrollArea>
      </Modal>
    </Box>
  );
};

export default MiniGameResultsAccordion;
