import React from "react";
import { Card, Text, Badge, Button, Group, Spoiler } from "@mantine/core";
import { MiniGame } from "../dto/user";
//@ts-ignore
import DOMPurify from "dompurify";
import { getMiniGameCategory } from "../Pages/AdminMiniGames";

interface MiniGameCardProps {
  miniGame: MiniGame;
  onPlay: (miniGameId: number) => void;
}

const MiniGameCard: React.FC<MiniGameCardProps> = ({ miniGame, onPlay }) => {
  const sanitizedDescription = DOMPurify.sanitize(miniGame.description as any);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder w={280}>
      <Group justify="space-between" mb="xs">
        <Text fw={500}>{miniGame.title}</Text>
        {miniGame.timeLimit !== 0 && (
          <Badge color="blue" variant="light">
            {((miniGame.timeLimit ?? 0) / 60) % 1 === 0
              ? (miniGame.timeLimit ?? 0) / 60
              : ((miniGame.timeLimit ?? 0) / 60).toFixed(2)}{" "}
            minutes
          </Badge>
        )}
        <Badge color="yellow" variant="light">
          {getMiniGameCategory(miniGame.category)}
        </Badge>
      </Group>

      <Spoiler maxHeight={100} showLabel="Voir plus" hideLabel="Cacher">
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          style={{ fontSize: "14px", color: "var(--mantine-color-dimmed)" }}
        />
      </Spoiler>

      <Button
        variant="light"
        color="blue"
        fullWidth
        mt="md"
        radius="md"
        onClick={() => onPlay(miniGame.id ?? 0)}
        // disabled={true}
      >
        Jouer au Mini-Jeu
      </Button>
    </Card>
  );
};

export default MiniGameCard;
