import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";
import FeedbackForm from "./FeedbackForm";

interface FeedbackButtonProps {
  pageUrl?: string;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ pageUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Button onClick={handleOpenModal} variant="light">
        Feedback sur la page
      </Button>
      <Modal
        opened={isModalOpen}
        onClose={handleCloseModal}
        title="Donnez votre feedback"
      >
        <FeedbackForm pageUrl={pageUrl} onSubmitSuccess={handleCloseModal} />
      </Modal>
    </>
  );
};

export default FeedbackButton;
