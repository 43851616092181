import axios from "axios";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.growspace.app"
    : "http://localhost:8080";

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
});

const publicRoutes = [
  { pattern: /\/auth\/login$/, method: "POST" },
  { pattern: /\/auth\/register$/, method: "POST" },
];

export function isPublicRoute(url: string, method: string) {
  return publicRoutes.some(
    (route) => route.pattern.test(url) && method === route.method
  );
}

export function useApi() {
  const { setUser, user } = useContext(UserContext);

  api.interceptors.request.use((config) => {
    if (!isPublicRoute(config.url ?? "", config.method ?? "")) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (user && user.accessToken) {
        config.headers["Authorization"] = `Bearer ${user.accessToken}`;
      }
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!isPublicRoute(error.config.url, error.config.method)) {
        if (error.response && error.response.status === 401 && user) {
          localStorage.removeItem("user");
          setUser(null);
          window.location.reload();
        }
      }
      return Promise.reject(error);
    }
  );

  return api;
}
