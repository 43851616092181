import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { finishUserQuest } from "../apiRoutes";
import { UserQuest, DailyQuestValidation } from "../dto/user";
import FinishQuestModal from "./FinishQuestModal";
import { startOfDay, isEqual } from "date-fns";

interface FinishQuestModalWrapperProps {
  selectedQuest: UserQuest | null;
  onClose: () => void;
}

const isDateValidated = (validations: DailyQuestValidation[], date: Date) => {
  const targetDate = startOfDay(date);
  return validations.some(
    (validation) =>
      validation.date &&
      isEqual(startOfDay(new Date(validation.date)), targetDate)
  );
};

const FinishQuestModalWrapper: React.FC<FinishQuestModalWrapperProps> = ({
  selectedQuest,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const api = useApi();
  const queryClient = useQueryClient();

  const handleFinishQuest = async (date: Date) => {
    if (selectedQuest) {
      try {
        await finishUserQuest(api, selectedQuest.id!, date);
        queryClient.invalidateQueries({ queryKey: ["userQuests"] });
        setIsModalOpen(false);
        onClose();
      } catch (error) {
        console.error("Error finishing quest:", error);
      }
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <FinishQuestModal
      opened={isModalOpen}
      onClose={handleClose}
      onFinish={handleFinishQuest}
      isValidatedToday={
        selectedQuest
          ? isDateValidated(
              selectedQuest.DailyQuestValidation || [],
              new Date()
            )
          : false
      }
      startDate={
        selectedQuest && selectedQuest.startDate
          ? new Date(selectedQuest.startDate)
          : new Date()
      }
      lastValidationDate={(() => {
        if (
          selectedQuest &&
          selectedQuest.DailyQuestValidation &&
          selectedQuest.DailyQuestValidation.length > 0
        ) {
          const lastValidation =
            selectedQuest.DailyQuestValidation[
              selectedQuest.DailyQuestValidation.length - 1
            ];
          return lastValidation.date ? new Date(lastValidation.date) : null;
        }
        return null;
      })()}
    />
  );
};

export default FinishQuestModalWrapper;
