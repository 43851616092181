import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  Text,
  SimpleGrid,
  Paper,
  Group,
  ThemeIcon,
  Progress,
  Stack,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { getUserQuests } from "../apiRoutes";
import { IconTrophy } from "@tabler/icons-react";

interface Achievement {
  id: number;
  title: string;
  description: string;
  requiredSteps: number;
  icon: React.ReactNode;
}

const achievements: Achievement[] = [
  {
    id: 1,
    title: "Premier pas",
    description: "Effectuez votre premier petit pas",
    requiredSteps: 1,
    icon: <IconTrophy size={24} />,
  },
  {
    id: 2,
    title: "En bonne voie",
    description: "Effectuez 10 petits pas",
    requiredSteps: 10,
    icon: <IconTrophy size={24} />,
  },
  {
    id: 3,
    title: "Habitude en formation",
    description: "Effectuez 25 petits pas",
    requiredSteps: 25,
    icon: <IconTrophy size={24} />,
  },
  {
    id: 4,
    title: "Mi-parcours",
    description: "Effectuez 50 petits pas",
    requiredSteps: 50,
    icon: <IconTrophy size={24} />,
  },
  {
    id: 5,
    title: "Maître des petits pas",
    description: "Effectuez 100 petits pas",
    requiredSteps: 100,
    icon: <IconTrophy size={24} />,
  },
];

const Achievements: React.FC = () => {
  const api = useApi();
  const [totalSteps, setTotalSteps] = useState(0);

  const { data: userQuests, isLoading } = useQuery({
    queryKey: ["userQuests"],
    queryFn: () => getUserQuests(api),
  });

  useEffect(() => {
    if (userQuests) {
      const steps = userQuests.reduce(
        (total, quest) => total + (quest.DailyQuestValidation?.length || 0),
        0
      );
      setTotalSteps(steps);
    }
  }, [userQuests]);

  const getAchievementStatus = (achievement: Achievement) => {
    const progress = Math.min(
      (totalSteps / achievement.requiredSteps) * 100,
      100
    );
    const isUnlocked = totalSteps >= achievement.requiredSteps;
    return { progress, isUnlocked };
  };

  if (isLoading) return <Text>Chargement des achèvements...</Text>;

  return (
    <Stack gap="xl" p={16}>
      <Box>
        <Title order={2} mb="xs">
          Achèvements
        </Title>
        <Text size="lg" mb="md">
          Total des petits pas effectués : {totalSteps}
        </Text>
      </Box>

      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 3 }}
        spacing={{ base: "sm", sm: "md", lg: "lg" }}
        verticalSpacing={{ base: "sm", sm: "md", lg: "lg" }}
      >
        {achievements.map((achievement) => {
          const { progress, isUnlocked } = getAchievementStatus(achievement);
          return (
            <Paper key={achievement.id} p="md" withBorder>
              <Group align="flex-start">
                <ThemeIcon
                  color={isUnlocked ? "gold" : "gray"}
                  size={40}
                  radius="xl"
                >
                  {achievement.icon}
                </ThemeIcon>
                <Box style={{ flex: 1 }}>
                  <Text fw={500} mb={5}>
                    {achievement.title}
                  </Text>
                  <Text size="sm" c="dimmed" mb={10}>
                    {achievement.description}
                  </Text>
                  <Progress value={progress} size="lg" radius="xl" mb={5} />
                  <Text size="xs" ta="right">
                    {Math.min(totalSteps, achievement.requiredSteps)}/
                    {achievement.requiredSteps}
                  </Text>
                </Box>
              </Group>
            </Paper>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
};

export default Achievements;
