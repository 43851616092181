import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import {
  Box,
  Text,
  Progress,
  Title,
  LoadingOverlay,
  Alert,
  Button,
} from "@mantine/core";
import { IconAlertCircle, IconArrowLeft } from "@tabler/icons-react";
import SingleQuestionGame from "../Components/games-category/SingleQuestionGame";
import DOMPurify from "dompurify";
import ChatGame from "../Components/games-category/ChatGame";
import QuizGame from "../Components/games-category/QuizGame";

interface MiniGameData {
  id: number;
  title: string;
  description: string;
  timeLimit: number;
  category: "SINGLE_QUESTION" | "CHAT" | "QUIZ";
  skillId: number;
}

const MiniGame: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const api = useApi();

  const [timeLeft, setTimeLeft] = useState(0);
  const [gameState, setGameState] = useState<
    "not_started" | "started" | "finished" | "submitted"
  >("not_started");

  const { data: gameData, isLoading: isGameDataLoading } =
    useQuery<MiniGameData>({
      queryKey: ["miniGame", id],
      queryFn: () => api.get(`/mini-games/${id}`).then((res) => res.data),
    });

  const hasTimeLimit = gameData?.timeLimit !== 0;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (gameState === "started" && timeLeft > 0 && hasTimeLimit) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setGameState("finished");
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gameState, timeLeft, hasTimeLimit]);

  const handleReturnToSkill = () => {
    if (gameData && gameData.skillId) {
      navigate(`/skills/${gameData.skillId}`);
    }
  };

  if (isGameDataLoading) {
    return <LoadingOverlay visible={true} />;
  }

  if (!gameData) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur" color="red">
        Impossible de charger les données du mini-jeu. Veuillez réessayer plus
        tard.
      </Alert>
    );
  }

  return (
    <Box p="md">
      <Title order={2} mb="md">
        {gameData.title}
      </Title>
      {gameState === "not_started" && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(gameData.description || ""),
          }}
        />
      )}
      {gameState === "started" && hasTimeLimit && (
        <>
          <Text mb="sm" fw={700}>
            Temps restant: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toString().padStart(2, "0")}
          </Text>
          <Progress
            value={(timeLeft / gameData.timeLimit) * 100}
            mb="md"
            color={
              (timeLeft / gameData.timeLimit) * 100 < 20
                ? "red"
                : (timeLeft / gameData.timeLimit) * 100 < 40
                ? "yellow"
                : "blue"
            }
          />
        </>
      )}
      {gameData.category === "SINGLE_QUESTION" && (
        <SingleQuestionGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
        />
      )}
      {gameData.category === "CHAT" && (
        <ChatGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
        />
      )}
      {gameData.category === "QUIZ" && (
        <QuizGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
          hasTimeLimit={hasTimeLimit}
        />
      )}
      {/* Add other game type components here */}

      {gameState === "submitted" && (
        <Box mt="xl">
          <Button
            leftSection={<IconArrowLeft size="1rem" />}
            onClick={handleReturnToSkill}
          >
            Retourner à la compétence
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MiniGame;
