import { useMemo } from "react";
import { Skill } from "../dto/user";
import { format } from "date-fns";

export const useEvolutionData = (skills: Skill[] | undefined) => {
  return useMemo(() => {
    if (!skills) return [];

    const dateMap = new Map<string, { total: number; count: number }>();
    const skillLatestValues = new Map<number, number>();

    // First, collect all evaluations and sort them by date
    const allEvaluations = skills
      .flatMap((skill) =>
        (skill.skillEvaluations || []).map((evaluation) => ({
          skillId: skill.id,
          date: evaluation.date,
          level: evaluation.level,
        }))
      )
      .sort((a, b) => {
        const dateA = a.date ? new Date(a.date).getTime() : 0;
        const dateB = b.date ? new Date(b.date).getTime() : 0;
        return dateA - dateB;
      });

    // Process evaluations
    allEvaluations.forEach(({ skillId, date, level }) => {
      if (date && level !== undefined && skillId !== undefined) {
        const dateObj = new Date(date); // Convert string to Date object
        const dateStr = format(dateObj, "yyyy-MM-dd");

        // Update the latest value for this skill
        skillLatestValues.set(skillId, level);

        // Calculate the total for this day
        let dayTotal = 0;
        let dayCount = 0;
        skillLatestValues.forEach((value) => {
          dayTotal += value;
          dayCount++;
        });

        dateMap.set(dateStr, { total: dayTotal, count: dayCount });
      }
    });

    // Convert to array and calculate average
    return Array.from(dateMap, ([date, { total, count }]) => ({
      date,
      total,
      average: count > 0 ? total / count : 0,
    }));
  }, [skills]);
};
