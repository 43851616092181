import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Paper,
  Group,
  PaperProps,
  Button,
  Checkbox,
  Anchor,
  Stack,
  Switch,
  Divider,
  Center,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { UserContext } from "../context/UserContext";
import { useContext, useState } from "react";
import { login, loginWithGoogle, register, forgotPassword } from "../apiRoutes";
import { useApi } from "../useApi";
import { IconX } from "@tabler/icons-react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

export function LoginRegister(
  props: PaperProps & {
    type: "login" | "register";
    setState: React.Dispatch<React.SetStateAction<"login" | "register">>;
    close: () => void;
  }
) {
  const { type, setState, close, ...restProps } = props;
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const api = useApi();
  const navigate = useNavigate();
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      pseudo: "",
      password: "",
      terms: false,
      isPublicProfile: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Le mot de passe doit contenir au moins 6 caractères"
          : null,
      pseudo: (val) => {
        const pseudoRegex = /^[a-zA-Z0-9]{3,}$/;
        if (!pseudoRegex.test(val) && type === "register") {
          return "Le pseudo doit contenir au moins 3 caractères, sans espaces, et uniquement des lettres et des chiffres";
        }
        return null;
      },
      terms: (val) =>
        !val && type === "register"
          ? "Vous devez accepter les conditions d'utilisation"
          : null,
    },
  });

  const handleGoogleSuccess = async (credentialResponse: any) => {
    const decoded: any = jwtDecode(credentialResponse.credential);

    try {
      const ret = await loginWithGoogle(
        api,
        decoded.email,
        decoded.name,
        decoded.picture
      );
      localStorage.setItem("user", JSON.stringify(ret.data));
      setUser(ret.data);
      setIsLoggedIn(true);
      if (type === "register") navigate("/settings?newUser=true");
      close();

      showNotification({
        message: "Connexion réussie avec Google !",
        color: "green",
      });
    } catch (error) {
      console.error("Failed to login/register with Google", error);
      showNotification({
        message: "Échec de la connexion/inscription avec Google",
        icon: <IconX size="1.1rem" />,
        color: "red",
      });
    }
  };

  const handleFormSubmit = async (values: any) => {
    if (isForgotPassword) {
      try {
        await forgotPassword(api, values.email);
        showNotification({
          message:
            "Si l'email existe, un lien de réinitialisation a été envoyé.",
          color: "green",
        });
        setIsForgotPassword(false);
      } catch (error) {
        showNotification({
          message: "Une erreur est survenue. Veuillez réessayer.",
          color: "red",
        });
      }
      return;
    }

    if (props.type === "login") {
      try {
        const ret = await login(api, values.email, values.password);
        localStorage.setItem("user", JSON.stringify(ret.data));
        setUser(ret.data);
        setIsLoggedIn(true);
        close();
      } catch (error: any) {
        console.error("Failed to login", error);
        if (error.response?.status === 401) {
          form.setFieldError("password", "Wrong password");
        }
      }
    } else if (props.type === "register") {
      const ret = await register(
        api,
        form.values.email,
        form.values.password,
        form.values.pseudo,
        form.values.isPublicProfile
      );
      if (ret.data.error === "username_taken") {
        form.setFieldError("pseudo", "Pseudo déjà pris");
        showNotification({
          message: "Pseudo déjà pris",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      } else if (ret.data.error === "email_taken") {
        form.setFieldError("email", "Email déjà pris");
        showNotification({
          message: "Email déjà pris",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      } else {
        console.log("Registration successful");
        localStorage.setItem("user", JSON.stringify(ret.data));
        setUser(ret.data);
        setIsLoggedIn(true);
        close();
      }
    }
  };

  return (
    <Paper radius="md" {...restProps}>
      {!isForgotPassword && (
        <>
          <Group grow mb="md" mt="md">
            <Center>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  console.log("Login Failed");
                }}
                shape="pill"
                locale="fr"
                text={type === "register" ? "signup_with" : "signin_with"}
              />
            </Center>
          </Group>

          <Divider
            label="Ou continuer avec un email"
            labelPosition="center"
            my="lg"
          />
        </>
      )}

      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack>
          {type === "register" && !isForgotPassword && (
            <TextInput
              label="Pseudo"
              placeholder="Votre pseudo"
              {...form.getInputProps("pseudo")}
              radius="md"
              withAsterisk
            />
          )}

          <TextInput
            required
            label="Email"
            placeholder="hello@mantine.dev"
            {...form.getInputProps("email")}
            radius="md"
          />

          {!isForgotPassword && (
            <PasswordInput
              required
              label="Mot de passe"
              placeholder="Votre mot de passe"
              {...form.getInputProps("password")}
              radius="md"
            />
          )}

          {type === "register" && !isForgotPassword && (
            <>
              <Switch
                label="Profil public"
                {...form.getInputProps("isPublicProfile", { type: "checkbox" })}
              />
              <Checkbox
                label="J'accepte les conditions d'utilisation"
                radius="md"
                checked={form.values.terms}
                {...form.getInputProps("terms")}
              />
            </>
          )}
        </Stack>

        <Group justify="space-between" mt="xl">
          {!isForgotPassword && (
            <Anchor
              component="button"
              type="button"
              c="dimmed"
              onClick={() => {
                setState(type === "register" ? "login" : "register");
              }}
              size="xs"
            >
              {type === "register"
                ? "Vous avez déjà un compte? Connectez-vous"
                : "Vous n'avez pas de compte? Inscrivez-vous"}
            </Anchor>
          )}
          <Button type="submit" radius="xl">
            {isForgotPassword
              ? "Réinitialiser le mot de passe"
              : type === "register"
              ? "S'inscrire"
              : "Se connecter"}
          </Button>
        </Group>
      </form>

      {type === "login" && (
        <Text mt="sm" size="sm" ta="center">
          <Anchor onClick={() => setIsForgotPassword(!isForgotPassword)}>
            {isForgotPassword
              ? "Retour à la connexion"
              : "Mot de passe oublié ?"}
          </Anchor>
        </Text>
      )}
    </Paper>
  );
}
