import React from "react";
import { Box, Title } from "@mantine/core";
import AdminFeedback from "../Components/AdminFeedback";

const AdminFeedbackPage = () => {
  return (
    <Box p={16}>
      <Title order={1} mb="lg">
        Admin Feedback Management
      </Title>
      <AdminFeedback />
    </Box>
  );
};

export default AdminFeedbackPage;
