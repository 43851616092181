import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useApi } from "../useApi";

interface UpdateUserParams {
  id: number;
  pseudo?: string;
  profilePictureUrl?: string;
  priorityArea?: string;
  dreams?: string;
  goals?: string;
  isPublicProfile?: boolean;
  defaultColorScheme?: "light" | "dark";
}

interface UpdateUserResponse {
  id: number;
  pseudo: string;
  profilePictureUrl: string;
  priorityArea: string;
  dreams: string;
  goals: string;
  isPublicProfile: boolean;
  defaultColorScheme: "light" | "dark";
}

export const useUpdateUser = (
  options?: UseMutationOptions<UpdateUserResponse, Error, UpdateUserParams>
): UseMutationResult<UpdateUserResponse, Error, UpdateUserParams> => {
  const api = useApi();

  const mutationFn = async (
    updatedUser: UpdateUserParams
  ): Promise<UpdateUserResponse> => {
    const { id, ...data } = updatedUser; // Ensure `id` is excluded from data
    const response = await api.put(`/user/${id}`, data);
    return response.data;
  };

  const mutationOptions: UseMutationOptions<
    UpdateUserResponse,
    Error,
    UpdateUserParams
  > = {
    ...options, // Spread existing options to maintain extensibility
    mutationFn, // Set mutation function
    onSuccess: (data, variables, context) => {
      let currentUser = JSON.parse(localStorage.getItem("user") || "{}");

      // Update only the changed fields
      currentUser = {
        ...currentUser,
        ...data, // Spread the response data to update fields dynamically
      };
      localStorage.setItem("user", JSON.stringify(currentUser));
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  };

  return useMutation<UpdateUserResponse, Error, UpdateUserParams>(
    mutationOptions // Pass the options object
  );
};
