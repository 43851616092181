/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { UserContext } from "./context/UserContext";
import {
  AppShell as MantineAppShell,
  ScrollArea,
  useMantineTheme,
  useMantineColorScheme,
  TextInput,
  UnstyledButton,
  Center,
  Box,
  Collapse,
  Button,
  Group,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Header, headerLinks } from "./Components/Header";
import { NavBar } from "./Components/NavBar";
import { UserButton } from "./Components/UserButton";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import Footer from "./Components/Footer";
import { useNavigate } from "react-router-dom";
import classes from "./Components/Header.module.css";

export const displayUnused = window.location.host === "localhost:3000";

const SearchBox = () => {
  return (
    <TextInput
      placeholder="Recherche"
      size="xs"
      leftSection={
        <IconSearch style={{ width: 12, height: 12 }} stroke={1.5} />
      }
      rightSectionWidth={70}
      styles={{ section: { pointerEvents: "none" } }}
      mb="sm"
      mt={16}
      px={16}
    />
  );
};

export default function AppShell({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useContext(UserContext);
  const [opened, { toggle, close }] = useDisclosure();
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [
    openedLoginRegister,
    { open: openLoginRegister, close: closeLoginRegister },
  ] = useDisclosure(false);
  const [typeLoginRegister, setLoginRegister] = useState<"login" | "register">(
    "register"
  );
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();

  return (
    <MantineAppShell
      header={{ height: 60 }}
      navbar={{
        width: 220,
        breakpoint: "sm",
        collapsed: { mobile: !opened, desktop: !isLoggedIn && !opened },
      }}
      padding={isLoggedIn ? undefined : "md"}
    >
      <MantineAppShell.Header>
        <Header
          opened={opened}
          close={close}
          toggle={toggle}
          typeLoginRegister={typeLoginRegister}
          setLoginRegister={setLoginRegister}
          openedLoginRegister={openedLoginRegister}
          closeLoginRegister={closeLoginRegister}
          openLoginRegister={openLoginRegister}
        />
      </MantineAppShell.Header>

      <MantineAppShell.Navbar>
        {isLoggedIn && (displayUnused ? <SearchBox /> : <Box mb="md" />)}

        <MantineAppShell.Section component={ScrollArea}>
          {isLoggedIn && <NavBar close={close} />}
          {displayUnused && (
            <Box
              pt="md"
              style={
                isLoggedIn && {
                  borderTop: `1px solid ${
                    colorScheme === "light"
                      ? theme.colors.gray[3]
                      : theme.colors.dark[4]
                  }`,
                }
              }
              mb={isLoggedIn ? "md" : undefined}
            >
              <div onClick={() => navigate("/")} className={classes.link}>
                Home
              </div>
              <UnstyledButton className={classes.link} onClick={toggleLinks}>
                <Center inline>
                  <Box component="span" mr={5}>
                    Features
                  </Box>
                  <IconChevronDown
                    style={{ width: rem(16), height: rem(16) }}
                    color={theme.colors.theme[6]}
                  />
                </Center>
              </UnstyledButton>
              <Collapse in={linksOpened}>
                {headerLinks(theme.colors.theme[6])}
              </Collapse>
              <a href="#" className={classes.link}>
                Learn
              </a>
              <a href="#" className={classes.link}>
                Pricing
              </a>
            </Box>
          )}
        </MantineAppShell.Section>
        {isLoggedIn && <MantineAppShell.Section grow />}
        {!isLoggedIn && (
          <MantineAppShell.Section
            style={{
              borderTop: `${displayUnused ? "1px" : "0px"} solid ${
                colorScheme === "light"
                  ? theme.colors.gray[3]
                  : theme.colors.dark[4]
              }`,
            }}
            mt={displayUnused ? "md" : undefined}
          >
            <Group justify="center" p="md" grow>
              <Button
                variant="default"
                onClick={() => {
                  openLoginRegister();
                  setLoginRegister("login");
                  close();
                }}
              >
                Se connecter
              </Button>
              <Button
                onClick={() => {
                  openLoginRegister();
                  setLoginRegister("register");
                  close();
                }}
              >
                S'inscrire
              </Button>
            </Group>
          </MantineAppShell.Section>
        )}
        {isLoggedIn && (
          <MantineAppShell.Section
            style={{
              borderTop: `1px solid ${
                colorScheme === "light"
                  ? theme.colors.gray[3]
                  : theme.colors.dark[4]
              }`,
            }}
          >
            <UserButton close={close} />
          </MantineAppShell.Section>
        )}
      </MantineAppShell.Navbar>
      <MantineAppShell.Main
        style={{
          padding: isLoggedIn ? undefined : 0,
          paddingTop: undefined,
          paddingBottom: isLoggedIn ? undefined : 0,
        }}
      >
        {children}
        {!isLoggedIn && <Footer />}
      </MantineAppShell.Main>
    </MantineAppShell>
  );
}
