import React, { useMemo } from "react";
import { Text, Group } from "@mantine/core";
import { User } from "../dto/user";
import { subDays, isAfter } from "date-fns";

interface ActiveUsersStatsProps {
  users: User[];
}

const ActiveUsersStats: React.FC<ActiveUsersStatsProps> = ({ users }) => {
  const { dailyActiveUsers, weeklyActiveUsers } = useMemo(() => {
    const now = new Date();
    const oneDayAgo = subDays(now, 1);
    const oneWeekAgo = subDays(now, 7);

    const dailyActive = users.filter(
      (user) => user.updatedAt && isAfter(new Date(user.updatedAt), oneDayAgo)
    ).length;

    const weeklyActive = users.filter(
      (user) => user.updatedAt && isAfter(new Date(user.updatedAt), oneWeekAgo)
    ).length;

    return { dailyActiveUsers: dailyActive, weeklyActiveUsers: weeklyActive };
  }, [users]);

  return (
    <Group mb="sm" c="dimmed">
      <Text>Utilisateurs quotidiens : {dailyActiveUsers}</Text>
      <Text>Utilisateurs hebdomadaires : {weeklyActiveUsers}</Text>
    </Group>
  );
};

export default ActiveUsersStats;
