import { useContext, useEffect } from "react";
import { useMantineColorScheme } from "@mantine/core";
import { UserContext } from "./context/UserContext";

function ColorSchemeHandler() {
  const { user } = useContext(UserContext);
  const { setColorScheme } = useMantineColorScheme();

  useEffect(() => {
    if (user && user.defaultColorScheme) {
      setColorScheme(user.defaultColorScheme === "dark" ? "dark" : "light");
    }
  }, [user, setColorScheme]);

  return null;
}

export default ColorSchemeHandler;
