import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";

const useFetchData = (queryKey: string, url: string) => {
  const api = useApi();

  const fetchData = async () => {
    const response = await api.get(url);
    return response.data;
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: fetchData,
  });
};

export default useFetchData;
