import React, { useMemo } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { format, isValid } from "date-fns";
import { fr } from "date-fns/locale";

const ChartEvolution = ({
  chartData,
  dataKey,
}: {
  chartData: any[];
  dataKey: string;
}) => {
  const maxValue = useMemo(() => {
    const max = Math.max(...chartData.map((item) => item[dataKey]));
    return Math.max(10, Math.ceil(max / 10) * 10);
  }, [chartData, dataKey]);

  const yAxisTicks = useMemo(() => {
    const tickCount = 5;
    const step = Math.max(2, Math.ceil(maxValue / tickCount));
    return Array.from({ length: tickCount + 1 }, (_, i) => i * step);
  }, [maxValue]);

  const formatXAxis = (tickItem: string) => {
    const date = new Date(tickItem);
    return isValid(date)
      ? format(date, "d MMM yyyy", { locale: fr })
      : tickItem;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={chartData}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <XAxis dataKey="date" tickFormatter={formatXAxis} />
        <YAxis domain={[0, maxValue]} ticks={yAxisTicks} />
        <Tooltip
          labelFormatter={(label) => formatXAxis(label as string)}
          formatter={(value) => [value, dataKey]}
        />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorGradient)"
          dot={{ fill: "#8884d8", r: 3 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ChartEvolution;
