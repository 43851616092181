// ProfileButtons.tsx
import React from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { IconShare, IconUser, IconLock } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";

interface ProfileButtonsProps {
  username: string;
  isPublicProfile: boolean;
  showProfileButton?: boolean;
}

const ProfileButtons: React.FC<ProfileButtonsProps> = ({
  username,
  isPublicProfile,
  showProfileButton = true,
}) => {
  const navigate = useNavigate();

  const handleShare = () => {
    if (!isPublicProfile) {
      notifications.show({
        title: "Profil non partageable",
        message:
          "Rendez votre profil public dans les paramètres pour le partager",
      });
      return;
    }

    const profileUrl = `https://growspace.app/user/${username}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Profil de ${username}`,
          text: `Découvrez le profil de ${username} sur GrowSpace`,
          url: profileUrl,
        })
        .then(() => {
          console.log("Contenu partagé avec succès");
        })
        .catch((error) => {
          console.log("Erreur lors du partage", error);
        });
    } else {
      navigator.clipboard
        .writeText(profileUrl)
        .then(() => {
          notifications.show({
            title: "Lien copié",
            message: "Le lien du profil a été copié dans le presse-papier",
            color: "green",
          });
        })
        .catch((error) => {
          console.error("Erreur lors de la copie du lien:", error);
          notifications.show({
            title: "Erreur",
            message: "Impossible de copier le lien",
            color: "red",
          });
        });
    }
  };

  const ShareButton = (
    <Button
      variant="subtle"
      leftSection={
        isPublicProfile ? <IconShare size={16} /> : <IconLock size={16} />
      }
      onClick={handleShare}
      disabled={!isPublicProfile}
    >
      {isPublicProfile ? "Partager" : "Non partageable"}
    </Button>
  );

  return (
    <Group>
      {isPublicProfile ? (
        ShareButton
      ) : (
        <Tooltip
          label="Rendez votre profil public pour le partager"
          position="top"
        >
          {ShareButton}
        </Tooltip>
      )}
      {showProfileButton && (
        <Button
          variant="subtle"
          leftSection={<IconUser size={16} />}
          onClick={() => navigate(`/user/${username}`)}
        >
          Voir mon profil
        </Button>
      )}
    </Group>
  );
};

export default ProfileButtons;
