import { Avatar, Flex, Title } from "@mantine/core";
// import { MantineLogo } from "@mantinex/mantine-logo";
import React from "react";
import { useNavigate } from "react-router-dom";

const Logo = ({ close }: { close?: () => void }) => {
  const navigate = useNavigate();
  return (
    <Flex
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate("/");
        close && close();
      }}
    >
      {/* <MantineLogo size={30} type={"mark"} color="theme" /> */}
      <Avatar src="/logo_purple.png" radius="xl" size={30} />
      <Title order={3} ml={10} style={{}}>
        GrowSpace
      </Title>
    </Flex>
  );
};

export default Logo;
