export const validateRatingTableJSON = (value: string): string | null => {
  if (!value) return null; // Field is not required
  try {
    const parsed = JSON.parse(value);
    if (!Array.isArray(parsed)) return "Invalid JSON format: must be an array";

    for (const item of parsed) {
      if (typeof item !== "object") return "Each item must be an object";
      if (!("niveau" in item) || !("description" in item)) {
        return "Each item must have 'niveau' and 'description' properties";
      }
      if (
        typeof item.niveau !== "number" ||
        item.niveau < 0 ||
        item.niveau > 10
      ) {
        return "Niveau must be a number between 0 and 10";
      }
      if (
        typeof item.description !== "string" ||
        item.description.length === 0
      ) {
        return "Description must be a non-empty string";
      }
    }

    // Check if all levels from 0 to 10 are present
    const levels = parsed.map((item: any) => item.niveau);
    for (let i = 0; i <= 10; i++) {
      if (!levels.includes(i)) {
        return `Missing level ${i} in the rating table`;
      }
    }

    return null; // Validation passed
  } catch (error) {
    return "Invalid JSON format";
  }
};
