import { Box, Group, Loader, Text } from "@mantine/core";
import { IconCheck, IconPencil, IconX } from "@tabler/icons-react";
import React from "react";

const SaveStatus = ({ saveStatus }: { saveStatus: string | null }) => {
  if (!saveStatus) return null;
  return (
    <Box
      style={{
        position: "fixed",
        bottom: 16,
        right: 16,
        padding: "8px 16px",
        borderRadius: "8px",
        backdropFilter: "blur(10px)", // Apply blur effect
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
      }}
    >
      {saveStatus === "saving" && (
        <Group>
          <Loader size="xs" />
          <Text size="sm" c="theme">
            Saving...
          </Text>
        </Group>
      )}
      {saveStatus === "saved" && (
        <Group>
          <IconCheck color="green" size={18} />
          <Text size="sm" c="green">
            Saved
          </Text>
        </Group>
      )}
      {saveStatus === "error" && (
        <Group>
          <IconX color="red" size={18} />
          <Text size="sm" c="red">
            Error saving
          </Text>
        </Group>
      )}
      {saveStatus === "unsaved" && (
        <Group>
          <IconPencil color="gray" size={18} />
          <Text size="sm" c="gray">
            Unsaved changes...
          </Text>
        </Group>
      )}
    </Box>
  );
};

export default SaveStatus;
