import { Badge, Group, Text } from "@mantine/core";
import React from "react";

const WelcomeMessage = () => {
  return (
    <Group mt={16}>
      <Text>
        <Badge color="green" mr="sm" component="span">
          Info
        </Badge>
        Commencez votre parcours avec nos{" "}
        <Text span c="theme" inherit>
          quêtes
        </Text>{" "}
        personnalisées. Ces quêtes vous permettent de réaliser des{" "}
        <Text span c="theme" inherit>
          petits pas
        </Text>{" "}
        vers vos objectifs, et de gagner des{" "}
        <Text span c="theme" inherit>
          récompenses
        </Text>{" "}
        ! Vous pouvez également{" "}
        <Text span c="theme" inherit>
          partager
        </Text>{" "}
        votre graphique des quêtes avec vos amis pour créer des partenaires de
        responsabilités. Explorez les quêtes disponibles dans chaque compétence
        de votre choix.
      </Text>
    </Group>
  );
};

export default WelcomeMessage;
