import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { Box, Title, Text, Paper, Group, Badge } from "@mantine/core";
import { FeedbackStatus, FeedbackCategory } from "../dto/user";

interface Feedback {
  id: number;
  title: string;
  content: string;
  category: FeedbackCategory;
  status: FeedbackStatus;
  createdAt: string;
}

export const translateCategory = (category: FeedbackCategory): string => {
  switch (category) {
    case "BUG":
      return "Bug";
    case "FEATURE_REQUEST":
      return "Demande de fonctionnalité";
    case "HELP":
      return "Aide";
    case "GENERAL_FEEDBACK":
      return "Feedback général";
    case "OTHER":
      return "Autre";
    default:
      return category;
  }
};

const translateStatus = (status: FeedbackStatus): string => {
  switch (status) {
    case "OPEN":
      return "Ouvert";
    case "IN_PROGRESS":
      return "En cours";
    case "CLOSED":
      return "Fermé";
    case "RESOLVED":
      return "Résolu";
    default:
      return status;
  }
};

const FeedbackList = () => {
  const api = useApi();
  const {
    data: feedbackList,
    isLoading,
    error,
  } = useQuery<Feedback[]>({
    queryKey: ["userFeedback"],
    queryFn: () => api.get("/feedback").then((res) => res.data),
  });

  if (isLoading) return <Text>Chargement...</Text>;
  if (error)
    return <Text color="red">Erreur lors du chargement des feedbacks</Text>;

  return (
    <Box>
      <Title order={2} mb="md">
        Historique de vos feedbacks
      </Title>
      {feedbackList?.map((feedback) => (
        <Paper key={feedback.id} p="md" mb="sm" withBorder>
          <Group justify="space-between" mb="xs">
            <Text>{feedback.title}</Text>
            <Badge
              color={
                feedback.status === "OPEN"
                  ? "blue"
                  : feedback.status === "IN_PROGRESS"
                  ? "yellow"
                  : "green"
              }
            >
              {translateStatus(feedback.status)}
            </Badge>
          </Group>
          <Text size="sm" mb="xs">
            Catégorie: {translateCategory(feedback.category)}
          </Text>
          <Text
            size="sm"
            dangerouslySetInnerHTML={{ __html: feedback.content }}
          />
          <Text size="xs" c="dimmed" mt="xs">
            Soumis le: {new Date(feedback.createdAt).toLocaleDateString()}
          </Text>
        </Paper>
      ))}
    </Box>
  );
};

export default FeedbackList;
