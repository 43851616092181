import React from "react";
import { Modal, Button, Group, Text } from "@mantine/core";
import { format, isAfter, max, startOfDay, subDays, isSameDay } from "date-fns";

interface FinishQuestModalProps {
  opened: boolean;
  onClose: () => void;
  onFinish: (date: Date) => void;
  isValidatedToday: boolean;
  startDate: Date;
  lastValidationDate: Date | null;
}

const FinishQuestModal: React.FC<FinishQuestModalProps> = ({
  opened,
  onClose,
  onFinish,
  isValidatedToday,
  startDate,
  lastValidationDate,
}) => {
  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);

  const earliestPossibleEndDate = lastValidationDate
    ? max([startOfDay(startDate), startOfDay(lastValidationDate)])
    : startOfDay(startDate);

  const canFinishYesterday =
    (isAfter(yesterday, earliestPossibleEndDate) ||
      isSameDay(yesterday, earliestPossibleEndDate)) &&
    !isValidatedToday;

  const canFinishToday =
    isAfter(today, earliestPossibleEndDate) ||
    isSameDay(today, earliestPossibleEndDate);

  return (
    <Modal opened={opened} onClose={onClose} title="Finir la quête">
      <Text>Choisissez la date de fin de la quête :</Text>
      <Group mt="md">
        <Button
          onClick={() => onFinish(yesterday)}
          disabled={!canFinishYesterday}
        >
          Hier ({format(yesterday, "dd/MM/yyyy")})
        </Button>
        <Button onClick={() => onFinish(today)} disabled={!canFinishToday}>
          Aujourd'hui ({format(today, "dd/MM/yyyy")})
        </Button>
      </Group>
    </Modal>
  );
};

export default FinishQuestModal;
