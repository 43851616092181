import React from "react";
import { Box, Title, Tabs } from "@mantine/core";
import FeedbackForm from "../Components/FeedbackForm";
import FeedbackList from "../Components/FeedbackList";

const FeedbackPage = () => {
  return (
    <Box p={16}>
      <Title order={1} mb="lg">
        Centre de Feedback
      </Title>
      <Tabs defaultValue="submit">
        <Tabs.List>
          <Tabs.Tab value="submit">Soumettre un Feedback</Tabs.Tab>
          <Tabs.Tab value="history">Historique</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="submit" pt="xs">
          <Box mt="md">
            <Title order={2} mb="md">
              Soumettre un Nouveau Feedback
            </Title>
            <FeedbackForm />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="history" pt="xs">
          <Box mt="md">
            <FeedbackList />
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default FeedbackPage;
