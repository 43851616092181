import React, { useState } from "react";
import { Tooltip } from "@mantine/core";

interface PersistentTooltipProps {
  label: React.ReactNode;
  children: React.ReactNode;
  isMobile: boolean;
}

const PersistentTooltip: React.FC<PersistentTooltipProps> = ({
  label,
  children,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTouch = () => {
    if (isMobile) {
      setIsOpen(true);
      setTimeout(() => setIsOpen(false), 2000); // 2 seconds
    }
  };

  return (
    <Tooltip
      label={label}
      opened={isMobile ? isOpen : undefined}
      position="top"
      withArrow
    >
      <div onTouchStart={handleTouch}>{children}</div>
    </Tooltip>
  );
};

export default PersistentTooltip;
