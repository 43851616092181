import React, { useState, useEffect } from "react";
import { Modal, Button, Group, Slider, Text } from "@mantine/core";
import { areasOfLife } from "../utils/areasOfLife";

interface TargetLevelModalProps {
  opened: boolean;
  onClose: () => void;
  initialTargets: Record<string, number>;
  onSave: (targets: Record<string, number>) => void;
}

export function TargetLevelModal({
  opened,
  onClose,
  initialTargets,
  onSave,
}: TargetLevelModalProps) {
  const [targets, setTargets] = useState<Record<string, number>>({});

  useEffect(() => {
    if (opened) {
      setTargets(initialTargets);
    }
  }, [initialTargets, opened]);

  const handleSave = () => {
    onSave(targets);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Définir des objectifs">
      {areasOfLife.map((area) => (
        <Group key={area.value} mb="lg">
          <Text size="sm" style={{ width: 100 }}>
            {area.title}
          </Text>
          <Slider
            value={targets[area.value] || 0}
            onChange={(value) =>
              setTargets((prev) => ({ ...prev, [area.value]: value }))
            }
            min={0}
            max={10}
            step={1}
            style={{ flex: 1 }}
            marks={[
              { value: 0, label: "0" },
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            label={(value) => value.toString()}
          />
        </Group>
      ))}
      <Group justify="flex-end" mt="md">
        <Button onClick={handleSave}>Enregistrer</Button>
      </Group>
    </Modal>
  );
}
