import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PasswordInput, Button, Box, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useApi } from "../useApi";
import { showNotification } from "@mantine/notifications";
import { UserContext } from "../context/UserContext";

export function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const api = useApi();
  const { setUser, setIsLoggedIn } = useContext(UserContext);

  const form = useForm({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      newPassword: (value) =>
        value.length < 6 ? "Password must be at least 6 characters" : null,
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords do not match" : null,
    },
  });

  const handleSubmit = async (values: any) => {
    try {
      const response = await api.post(`/auth/reset-password/${token}`, {
        newPassword: values.newPassword,
      });

      if (response.data && response.data.accessToken) {
        // Store the entire user object in localStorage
        localStorage.setItem("user", JSON.stringify(response.data));

        // Update user context
        setUser(response.data);
        setIsLoggedIn(true);

        showNotification({
          title: "Succès",
          message:
            "Votre mot de passe a été réinitialisé avec succès. Vous êtes maintenant connecté.",
          color: "green",
        });

        // Redirect to the dashboard or home page
        navigate("/");
      } else {
        showNotification({
          title: "Succès",
          message:
            "Votre mot de passe a été réinitialisé avec succès. Veuillez vous connecter.",
          color: "green",
        });
        navigate("/login");
      }
    } catch (error) {
      showNotification({
        title: "Erreur",
        message:
          "Une erreur est survenue lors de la réinitialisation du mot de passe.",
        color: "red",
      });
    }
  };

  return (
    <Box m="lg">
      <Title order={3}>Réinitialisation du mot de passe</Title>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PasswordInput
          required
          label="Nouveau mot de passe"
          placeholder="Entrez votre nouveau mot de passe"
          {...form.getInputProps("newPassword")}
        />
        <PasswordInput
          required
          mt="md"
          label="Confirmer le nouveau mot de passe"
          placeholder="Confirmez votre nouveau mot de passe"
          {...form.getInputProps("confirmPassword")}
        />
        <Button type="submit" mt="md">
          Réinitialiser le mot de passe
        </Button>
      </form>
    </Box>
  );
}
